.App {
  text-align: center;
  margin: auto;
  width: 40%;
}

div id {
  text-align: center;
  margin: auto;
  width: 40%;
}
